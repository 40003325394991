import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { createTreeViewDefaultId } from "./useTreeViewId.utils.js";
export const useTreeViewId = ({
  params,
  state,
  setState
}) => {
  React.useEffect(() => {
    setState(prevState => {
      if (prevState.id.treeId === params.id && prevState.id.treeId !== undefined) {
        return prevState;
      }
      return _extends({}, prevState, {
        id: _extends({}, prevState.id, {
          treeId: params.id ?? createTreeViewDefaultId()
        })
      });
    });
  }, [setState, params.id]);
  const treeId = params.id ?? state.id.treeId;
  return {
    getRootProps: () => ({
      id: treeId
    }),
    contextValue: {
      treeId
    }
  };
};
useTreeViewId.params = {
  id: true
};
useTreeViewId.getInitialState = ({
  id
}) => ({
  id: {
    treeId: id ?? undefined
  }
});