import * as React from 'react';
import { useTreeViewContext } from "../../TreeViewProvider/index.js";
export const useTreeViewLabelItemPlugin = ({
  props
}) => {
  const {
    instance
  } = useTreeViewContext();
  const {
    label,
    itemId
  } = props;
  const [labelInputValue, setLabelInputValue] = React.useState(label);
  const isItemBeingEdited = instance.isItemBeingEdited(itemId);
  React.useEffect(() => {
    if (!isItemBeingEdited) {
      setLabelInputValue(label);
    }
  }, [isItemBeingEdited, label]);
  return {
    propsEnhancers: {
      labelInput: ({
        externalEventHandlers,
        interactions
      }) => {
        const editable = instance.isItemEditable(itemId);
        if (!editable) {
          return {};
        }
        const handleKeydown = event => {
          externalEventHandlers.onKeyDown?.(event);
          if (event.defaultMuiPrevented) {
            return;
          }
          const target = event.target;
          if (event.key === 'Enter' && target.value) {
            interactions.handleSaveItemLabel(event, target.value);
          } else if (event.key === 'Escape') {
            interactions.handleCancelItemLabelEditing(event);
          }
        };
        const handleBlur = event => {
          externalEventHandlers.onBlur?.(event);
          if (event.defaultMuiPrevented) {
            return;
          }
          if (event.target.value) {
            interactions.handleSaveItemLabel(event, event.target.value);
          }
        };
        const handleInputChange = event => {
          externalEventHandlers.onChange?.(event);
          setLabelInputValue(event.target.value);
        };
        return {
          value: labelInputValue ?? '',
          'data-element': 'labelInput',
          onChange: handleInputChange,
          onKeyDown: handleKeydown,
          onBlur: handleBlur,
          autoFocus: true,
          type: 'text'
        };
      }
    }
  };
};