import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { warnOnce } from '@mui/x-internals/warning';
import { chooseActionToApply, isAncestor, moveItemInTree } from "./useTreeViewItemsReordering.utils.js";
import { useTreeViewItemsReorderingItemPlugin } from "./useTreeViewItemsReordering.itemPlugin.js";
export const useTreeViewItemsReordering = ({
  params,
  instance,
  state,
  setState
}) => {
  const canItemBeDragged = React.useCallback(itemId => {
    if (!params.itemsReordering) {
      return false;
    }
    const isItemReorderable = params.isItemReorderable;
    if (isItemReorderable) {
      return isItemReorderable(itemId);
    }
    return true;
  }, [params.itemsReordering, params.isItemReorderable]);
  const getDroppingTargetValidActions = React.useCallback(itemId => {
    const itemsReordering = state.itemsReordering;
    if (!itemsReordering) {
      throw new Error('There is no ongoing reordering.');
    }
    if (itemId === itemsReordering.draggedItemId) {
      return {};
    }
    const canMoveItemToNewPosition = params.canMoveItemToNewPosition;
    const targetItemMeta = instance.getItemMeta(itemId);
    const targetItemIndex = instance.getItemIndex(targetItemMeta.id);
    const draggedItemMeta = instance.getItemMeta(itemsReordering.draggedItemId);
    const draggedItemIndex = instance.getItemIndex(draggedItemMeta.id);
    const oldPosition = {
      parentId: draggedItemMeta.parentId,
      index: draggedItemIndex
    };
    const checkIfPositionIsValid = positionAfterAction => {
      let isValid;
      // If the new position is equal to the old one, we don't want to show any dropping UI.
      if (positionAfterAction.parentId === oldPosition.parentId && positionAfterAction.index === oldPosition.index) {
        isValid = false;
      } else if (canMoveItemToNewPosition) {
        isValid = canMoveItemToNewPosition({
          itemId: itemsReordering.draggedItemId,
          oldPosition,
          newPosition: positionAfterAction
        });
      } else {
        isValid = true;
      }
      return isValid;
    };
    const positionsAfterAction = {
      'make-child': {
        parentId: targetItemMeta.id,
        index: 0
      },
      'reorder-above': {
        parentId: targetItemMeta.parentId,
        index: targetItemMeta.parentId === draggedItemMeta.parentId && targetItemIndex > draggedItemIndex ? targetItemIndex - 1 : targetItemIndex
      },
      'reorder-below': targetItemMeta.expandable ? null : {
        parentId: targetItemMeta.parentId,
        index: targetItemMeta.parentId === draggedItemMeta.parentId && targetItemIndex > draggedItemIndex ? targetItemIndex : targetItemIndex + 1
      },
      'move-to-parent': targetItemMeta.parentId == null ? null : {
        parentId: targetItemMeta.parentId,
        index: instance.getItemOrderedChildrenIds(targetItemMeta.parentId).length
      }
    };
    const validActions = {};
    Object.keys(positionsAfterAction).forEach(action => {
      const positionAfterAction = positionsAfterAction[action];
      if (positionAfterAction != null && checkIfPositionIsValid(positionAfterAction)) {
        validActions[action] = positionAfterAction;
      }
    });
    return validActions;
  }, [instance, state.itemsReordering, params.canMoveItemToNewPosition]);
  const startDraggingItem = React.useCallback(itemId => {
    setState(prevState => _extends({}, prevState, {
      itemsReordering: {
        targetItemId: itemId,
        draggedItemId: itemId,
        action: null,
        newPosition: null
      }
    }));
  }, [setState]);
  const stopDraggingItem = React.useCallback(itemId => {
    if (state.itemsReordering == null || state.itemsReordering.draggedItemId !== itemId) {
      return;
    }
    if (state.itemsReordering.draggedItemId === state.itemsReordering.targetItemId || state.itemsReordering.action == null || state.itemsReordering.newPosition == null) {
      setState(prevState => _extends({}, prevState, {
        itemsReordering: null
      }));
      return;
    }
    const draggedItemMeta = instance.getItemMeta(state.itemsReordering.draggedItemId);
    const oldPosition = {
      parentId: draggedItemMeta.parentId,
      index: instance.getItemIndex(draggedItemMeta.id)
    };
    const newPosition = state.itemsReordering.newPosition;
    setState(prevState => _extends({}, prevState, {
      itemsReordering: null,
      items: moveItemInTree({
        itemToMoveId: itemId,
        newPosition,
        oldPosition,
        prevState: prevState.items
      })
    }));
    const onItemPositionChange = params.onItemPositionChange;
    onItemPositionChange?.({
      itemId,
      newPosition,
      oldPosition
    });
  }, [setState, state.itemsReordering, instance, params.onItemPositionChange]);
  const setDragTargetItem = React.useCallback(({
    itemId,
    validActions,
    targetHeight,
    cursorY,
    cursorX,
    contentElement
  }) => {
    setState(prevState => {
      const prevSubState = prevState.itemsReordering;
      if (prevSubState == null || isAncestor(instance, itemId, prevSubState.draggedItemId)) {
        return prevState;
      }
      const action = chooseActionToApply({
        itemChildrenIndentation: params.itemChildrenIndentation,
        validActions,
        targetHeight,
        targetDepth: prevState.items.itemMetaMap[itemId].depth,
        cursorY,
        cursorX,
        contentElement
      });
      const newPosition = action == null ? null : validActions[action];
      if (prevSubState.targetItemId === itemId && prevSubState.action === action && prevSubState.newPosition?.parentId === newPosition?.parentId && prevSubState.newPosition?.index === newPosition?.index) {
        return prevState;
      }
      return _extends({}, prevState, {
        itemsReordering: _extends({}, prevSubState, {
          targetItemId: itemId,
          newPosition,
          action
        })
      });
    });
  }, [instance, setState, params.itemChildrenIndentation]);
  return {
    instance: {
      canItemBeDragged,
      getDroppingTargetValidActions,
      startDraggingItem,
      stopDraggingItem,
      setDragTargetItem
    },
    contextValue: {
      itemsReordering: {
        enabled: params.itemsReordering,
        currentDrag: state.itemsReordering
      }
    }
  };
};
useTreeViewItemsReordering.itemPlugin = useTreeViewItemsReorderingItemPlugin;
useTreeViewItemsReordering.getDefaultizedParams = ({
  params,
  experimentalFeatures
}) => {
  const canUseFeature = experimentalFeatures?.indentationAtItemLevel && experimentalFeatures?.itemsReordering;
  if (process.env.NODE_ENV !== 'production') {
    if (params.itemsReordering && !canUseFeature) {
      warnOnce(['MUI X: The items reordering feature requires the `indentationAtItemLevel` and `itemsReordering` experimental features to be enabled.', 'You can do it by passing `experimentalFeatures={{ indentationAtItemLevel: true, itemsReordering: true }}` to the `<RichTreeViewPro />`component.', 'Check the documentation for more details: https://mui.com/x/react-tree-view/rich-tree-view/items/']);
    }
  }
  return _extends({}, params, {
    itemsReordering: canUseFeature ? params.itemsReordering ?? false : false
  });
};
useTreeViewItemsReordering.getInitialState = () => ({
  itemsReordering: null
});
useTreeViewItemsReordering.params = {
  itemsReordering: true,
  isItemReorderable: true,
  canMoveItemToNewPosition: true,
  onItemPositionChange: true
};