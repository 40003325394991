import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import extractEventHandlers from '@mui/utils/extractEventHandlers';
import useForkRef from '@mui/utils/useForkRef';
import { useTreeViewContext } from "../internals/TreeViewProvider/index.js";
import { useTreeItem2Utils } from "../hooks/useTreeItem2Utils/index.js";
import { TreeViewItemDepthContext } from "../internals/TreeViewItemDepthContext/index.js";
import { isTargetInDescendants } from "../internals/utils/tree.js";
import { generateTreeItemIdAttribute } from "../internals/corePlugins/useTreeViewId/useTreeViewId.utils.js";
export const useTreeItem2 = parameters => {
  const {
    runItemPlugins,
    items: {
      onItemClick,
      disabledItemsFocusable,
      indentationAtItemLevel
    },
    selection: {
      disableSelection,
      checkboxSelection
    },
    expansion: {
      expansionTrigger
    },
    treeId,
    instance,
    publicAPI
  } = useTreeViewContext();
  const depthContext = React.useContext(TreeViewItemDepthContext);
  const {
    id,
    itemId,
    label,
    children,
    rootRef
  } = parameters;
  const {
    rootRef: pluginRootRef,
    contentRef,
    propsEnhancers
  } = runItemPlugins(parameters);
  const {
    interactions,
    status
  } = useTreeItem2Utils({
    itemId,
    children
  });
  const rootRefObject = React.useRef(null);
  const contentRefObject = React.useRef(null);
  const handleRootRef = useForkRef(rootRef, pluginRootRef, rootRefObject);
  const handleContentRef = useForkRef(contentRef, contentRefObject);
  const checkboxRef = React.useRef(null);
  const idAttribute = generateTreeItemIdAttribute({
    itemId,
    treeId,
    id
  });
  const rootTabIndex = instance.canItemBeTabbed(itemId) ? 0 : -1;
  const sharedPropsEnhancerParams = {
    rootRefObject,
    contentRefObject,
    interactions
  };
  const createRootHandleFocus = otherHandlers => event => {
    otherHandlers.onFocus?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    const canBeFocused = !status.disabled || disabledItemsFocusable;
    if (!status.focused && canBeFocused && event.currentTarget === event.target) {
      instance.focusItem(event, itemId);
    }
  };
  const createRootHandleBlur = otherHandlers => event => {
    otherHandlers.onBlur?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    const rootElement = instance.getItemDOMElement(itemId);

    // Don't blur the root when switching to editing mode
    // the input that triggers the root blur can be either the relatedTarget (when entering editing state) or the target (when exiting editing state)
    // when we enter the editing state, we focus the input -> we don't want to remove the focused item from the state
    if (status.editing ||
    // we can exit the editing state by clicking outside the input (within the Tree Item) or by pressing Enter or Escape -> we don't want to remove the focused item from the state in these cases
    // we can also exit the editing state by clicking on the root itself -> want to remove the focused item from the state in this case
    event.relatedTarget && isTargetInDescendants(event.relatedTarget, rootElement) && (event.target && event.target?.dataset?.element === 'labelInput' && isTargetInDescendants(event.target, rootElement) || event.relatedTarget?.dataset?.element === 'labelInput')) {
      return;
    }
    instance.removeFocusedItem();
  };
  const createRootHandleKeyDown = otherHandlers => event => {
    otherHandlers.onKeyDown?.(event);
    if (event.defaultMuiPrevented || event.target?.dataset?.element === 'labelInput') {
      return;
    }
    instance.handleItemKeyDown(event, itemId);
  };
  const createLabelHandleDoubleClick = otherHandlers => event => {
    otherHandlers.onDoubleClick?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    interactions.toggleItemEditing();
  };
  const createContentHandleClick = otherHandlers => event => {
    otherHandlers.onClick?.(event);
    onItemClick?.(event, itemId);
    if (event.defaultMuiPrevented || checkboxRef.current?.contains(event.target)) {
      return;
    }
    if (expansionTrigger === 'content') {
      interactions.handleExpansion(event);
    }
    if (!checkboxSelection) {
      interactions.handleSelection(event);
    }
  };
  const createContentHandleMouseDown = otherHandlers => event => {
    otherHandlers.onMouseDown?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }

    // Prevent text selection
    if (event.shiftKey || event.ctrlKey || event.metaKey || status.disabled) {
      event.preventDefault();
    }
  };
  const createCheckboxHandleChange = otherHandlers => event => {
    otherHandlers.onChange?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    if (disableSelection || status.disabled) {
      return;
    }
    interactions.handleCheckboxSelection(event);
  };
  const createIconContainerHandleClick = otherHandlers => event => {
    otherHandlers.onClick?.(event);
    if (event.defaultMuiPrevented) {
      return;
    }
    if (expansionTrigger === 'iconContainer') {
      interactions.handleExpansion(event);
    }
  };
  const getRootProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(parameters), extractEventHandlers(externalProps));

    // https://www.w3.org/WAI/ARIA/apg/patterns/treeview/
    let ariaSelected;
    if (status.selected) {
      // - each selected node has aria-selected set to true.
      ariaSelected = true;
    } else if (disableSelection || status.disabled) {
      // - if the tree contains nodes that are not selectable, aria-selected is not present on those nodes.
      ariaSelected = undefined;
    } else {
      // - all nodes that are selectable but not selected have aria-selected set to false.
      ariaSelected = false;
    }
    const props = _extends({}, externalEventHandlers, {
      ref: handleRootRef,
      role: 'treeitem',
      tabIndex: rootTabIndex,
      id: idAttribute,
      'aria-expanded': status.expandable ? status.expanded : undefined,
      'aria-selected': ariaSelected,
      'aria-disabled': status.disabled || undefined
    }, externalProps, {
      onFocus: createRootHandleFocus(externalEventHandlers),
      onBlur: createRootHandleBlur(externalEventHandlers),
      onKeyDown: createRootHandleKeyDown(externalEventHandlers)
    });
    if (indentationAtItemLevel) {
      props.style = {
        '--TreeView-itemDepth': typeof depthContext === 'function' ? depthContext(itemId) : depthContext
      };
    }
    const enhancedRootProps = propsEnhancers.root?.(_extends({}, sharedPropsEnhancerParams, {
      externalEventHandlers
    })) ?? {};
    return _extends({}, props, enhancedRootProps);
  };
  const getContentProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    const props = _extends({}, externalEventHandlers, externalProps, {
      ref: handleContentRef,
      onClick: createContentHandleClick(externalEventHandlers),
      onMouseDown: createContentHandleMouseDown(externalEventHandlers),
      status
    });
    if (indentationAtItemLevel) {
      props.indentationAtItemLevel = true;
    }
    const enhancedContentProps = propsEnhancers.content?.(_extends({}, sharedPropsEnhancerParams, {
      externalEventHandlers
    })) ?? {};
    return _extends({}, props, enhancedContentProps);
  };
  const getCheckboxProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, {
      visible: checkboxSelection,
      ref: checkboxRef,
      checked: status.selected,
      disabled: disableSelection || status.disabled,
      tabIndex: -1
    }, externalProps, {
      onChange: createCheckboxHandleChange(externalEventHandlers)
    });
  };
  const getLabelProps = (externalProps = {}) => {
    const externalEventHandlers = _extends({}, extractEventHandlers(externalProps));
    const props = _extends({}, externalEventHandlers, {
      children: label
    }, externalProps, {
      onDoubleClick: createLabelHandleDoubleClick(externalEventHandlers)
    });
    if (instance.isTreeViewEditable) {
      props.editable = status.editable;
    }
    return props;
  };
  const getLabelInputProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    const enhancedLabelInputProps = propsEnhancers.labelInput?.({
      rootRefObject,
      contentRefObject,
      externalEventHandlers,
      interactions
    }) ?? {};
    return _extends({}, externalProps, enhancedLabelInputProps);
  };
  const getIconContainerProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    return _extends({}, externalEventHandlers, externalProps, {
      onClick: createIconContainerHandleClick(externalEventHandlers)
    });
  };
  const getGroupTransitionProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    const response = _extends({}, externalEventHandlers, {
      unmountOnExit: true,
      component: 'ul',
      role: 'group',
      in: status.expanded,
      children
    }, externalProps);
    if (indentationAtItemLevel) {
      response.indentationAtItemLevel = true;
    }
    return response;
  };
  const getDragAndDropOverlayProps = (externalProps = {}) => {
    const externalEventHandlers = extractEventHandlers(externalProps);
    const enhancedDragAndDropOverlayProps = propsEnhancers.dragAndDropOverlay?.(_extends({}, sharedPropsEnhancerParams, {
      externalEventHandlers
    })) ?? {};
    return _extends({}, externalProps, enhancedDragAndDropOverlayProps);
  };
  return {
    getRootProps,
    getContentProps,
    getGroupTransitionProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getLabelInputProps,
    getDragAndDropOverlayProps,
    rootRef: handleRootRef,
    status,
    publicAPI
  };
};