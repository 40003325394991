import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { warnOnce } from '@mui/x-internals/warning';
import { useTreeViewLabelItemPlugin } from "./useTreeViewLabel.itemPlugin.js";
export const useTreeViewLabel = ({
  instance,
  state,
  setState,
  params
}) => {
  const editedItemRef = React.useRef(state.editedItemId);
  const isItemBeingEditedRef = itemId => editedItemRef.current === itemId;
  const setEditedItemId = editedItemId => {
    setState(prevState => _extends({}, prevState, {
      editedItemId
    }));
    editedItemRef.current = editedItemId;
  };
  const isItemBeingEdited = itemId => itemId === state.editedItemId;
  const isTreeViewEditable = Boolean(params.isItemEditable);
  const isItemEditable = itemId => {
    if (itemId == null || !isTreeViewEditable) {
      return false;
    }
    const item = instance.getItem(itemId);
    if (!item) {
      return false;
    }
    return typeof params.isItemEditable === 'function' ? params.isItemEditable(item) : Boolean(params.isItemEditable);
  };
  const updateItemLabel = (itemId, label) => {
    if (!label) {
      throw new Error(['MUI X: The Tree View component requires all items to have a `label` property.', 'The label of an item cannot be empty.', itemId].join('\n'));
    }
    setState(prevState => {
      const item = prevState.items.itemMetaMap[itemId];
      if (item.label !== label) {
        return _extends({}, prevState, {
          items: _extends({}, prevState.items, {
            itemMetaMap: _extends({}, prevState.items.itemMetaMap, {
              [itemId]: _extends({}, item, {
                label
              })
            })
          })
        });
      }
      return prevState;
    });
    if (params.onItemLabelChange) {
      params.onItemLabelChange(itemId, label);
    }
  };
  return {
    instance: {
      setEditedItemId,
      isItemBeingEdited,
      updateItemLabel,
      isItemEditable,
      isTreeViewEditable,
      isItemBeingEditedRef
    },
    publicAPI: {
      updateItemLabel
    }
  };
};
useTreeViewLabel.itemPlugin = useTreeViewLabelItemPlugin;
useTreeViewLabel.getDefaultizedParams = ({
  params,
  experimentalFeatures
}) => {
  const canUseFeature = experimentalFeatures?.labelEditing;
  if (process.env.NODE_ENV !== 'production') {
    if (params.isItemEditable && !canUseFeature) {
      warnOnce(['MUI X: The label editing feature requires the `labelEditing` experimental feature to be enabled.', 'You can do it by passing `experimentalFeatures={{ labelEditing: true}}` to the Rich Tree View Pro component.', 'Check the documentation for more details: https://mui.com/x/react-tree-view/rich-tree-view/editing/']);
    }
  }
  return _extends({}, params, {
    isItemEditable: canUseFeature ? params.isItemEditable ?? false : false
  });
};
useTreeViewLabel.getInitialState = () => ({
  editedItemId: null
});
useTreeViewLabel.params = {
  onItemLabelChange: true,
  isItemEditable: true
};