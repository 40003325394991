'use client';

import { useTreeViewContext } from "../internals/TreeViewProvider/index.js";
import { useTreeViewLabel } from "../internals/plugins/useTreeViewLabel/index.js";
import { hasPlugin } from "../internals/utils/plugins.js";
export function useTreeItemState(itemId) {
  const {
    instance,
    items: {
      onItemClick
    },
    selection: {
      multiSelect,
      checkboxSelection,
      disableSelection
    },
    expansion: {
      expansionTrigger
    }
  } = useTreeViewContext();
  const expandable = instance.isItemExpandable(itemId);
  const expanded = instance.isItemExpanded(itemId);
  const focused = instance.isItemFocused(itemId);
  const selected = instance.isItemSelected(itemId);
  const disabled = instance.isItemDisabled(itemId);
  const editing = instance?.isItemBeingEdited ? instance?.isItemBeingEdited(itemId) : false;
  const editable = instance.isItemEditable ? instance.isItemEditable(itemId) : false;
  const handleExpansion = event => {
    if (!disabled) {
      if (!focused) {
        instance.focusItem(event, itemId);
      }
      const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);

      // If already expanded and trying to toggle selection don't close
      if (expandable && !(multiple && instance.isItemExpanded(itemId))) {
        instance.toggleItemExpansion(event, itemId);
      }
    }
  };
  const handleSelection = event => {
    if (!disabled) {
      if (!focused) {
        instance.focusItem(event, itemId);
      }
      const multiple = multiSelect && (event.shiftKey || event.ctrlKey || event.metaKey);
      if (multiple) {
        if (event.shiftKey) {
          instance.expandSelectionRange(event, itemId);
        } else {
          instance.selectItem({
            event,
            itemId,
            keepExistingSelection: true
          });
        }
      } else {
        instance.selectItem({
          event,
          itemId,
          shouldBeSelected: true
        });
      }
    }
  };
  const handleCheckboxSelection = event => {
    if (disableSelection || disabled) {
      return;
    }
    const hasShift = event.nativeEvent.shiftKey;
    if (multiSelect && hasShift) {
      instance.expandSelectionRange(event, itemId);
    } else {
      instance.selectItem({
        event,
        itemId,
        keepExistingSelection: multiSelect,
        shouldBeSelected: event.target.checked
      });
    }
  };
  const preventSelection = event => {
    if (event.shiftKey || event.ctrlKey || event.metaKey || disabled) {
      // Prevent text selection
      event.preventDefault();
    }
  };
  const toggleItemEditing = () => {
    if (!hasPlugin(instance, useTreeViewLabel)) {
      return;
    }
    if (instance.isItemEditable(itemId)) {
      if (instance.isItemBeingEdited(itemId)) {
        instance.setEditedItemId(null);
      } else {
        instance.setEditedItemId(itemId);
      }
    }
  };
  const handleSaveItemLabel = (event, label) => {
    if (!hasPlugin(instance, useTreeViewLabel)) {
      return;
    }

    // As a side effect of `instance.focusItem` called here and in `handleCancelItemLabelEditing` the `labelInput` is blurred
    // The `onBlur` event is triggered, which calls `handleSaveItemLabel` again.
    // To avoid creating an unwanted behavior we need to check if the item is being edited before calling `updateItemLabel`
    // using `instance.isItemBeingEditedRef` instead of `instance.isItemBeingEdited` since the state is not yet updated in this point
    if (instance.isItemBeingEditedRef(itemId)) {
      instance.updateItemLabel(itemId, label);
      toggleItemEditing();
      instance.focusItem(event, itemId);
    }
  };
  const handleCancelItemLabelEditing = event => {
    if (!hasPlugin(instance, useTreeViewLabel)) {
      return;
    }
    if (instance.isItemBeingEditedRef(itemId)) {
      toggleItemEditing();
      instance.focusItem(event, itemId);
    }
  };
  return {
    disabled,
    expanded,
    selected,
    focused,
    editable,
    editing,
    disableSelection,
    checkboxSelection,
    handleExpansion,
    handleSelection,
    handleCheckboxSelection,
    handleContentClick: onItemClick,
    preventSelection,
    expansionTrigger,
    toggleItemEditing,
    handleSaveItemLabel,
    handleCancelItemLabelEditing
  };
}